import React, { useState, useEffect } from 'react';
import swal from 'sweetalert2'
import { Container, Row, Col, FormGroup } from 'react-bootstrap';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import Checkbox from '@mui/material/Checkbox';
import ChooseOriginals from '../../screens/chooseOriginals/ChooseOriginals';
import DisabledText from '../disabledText/DisabledText';
import OutLineButton from '../outLineButton/OutLineButton';
import MultipleReplacement from '../deviationRequest/multipleReplacement/MultipleReplacement';
import ValidateReplacementModal from '../validateReplacementModal/validateReplacementModal'
import './styling.css';
import moment from 'moment';

const DeviationRequestMobile = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [timeFrame, setTimeFrame] = useState(new Date());
  const [specChgReq, setSpecChgReq] = useState(false);
  const [npdNumber, setNpdNumber] = useState('');

  console.log('describeType', props.describeType);
  const { describeType,
    deviationDetails,
    plantCodes,
    onValidateDeviationMaterial,
    handleBottomSheetClose,
    onSaveDeviation,
    userInfo,
    deviationType,
    setCurrentStage,
    officialProductDetails,
    zwcpDetails,
    materialNumber,
    plants,
    additionalComponents,
    onGetAdditionalComponents,
    onSetAdditionalComponents,
    setLoading,
    setIngredientStatement
  } = props;


  /**
   * areVerified is used to enable/disable save and review button
   */
  const [areVerified, setAreVerified] = useState(false);
  const [isOriginalsChosen, setOriginalsChosen] = useState(false);
  const [replacementsValidated, setReplacementsValidated] = useState(false);
  const [replacementsValid, setReplacementsValid] = useState(false);
  const [showOriginals, setShowOriginals] = useState(false);
  /**
   * Originals will be selected from the steps popup
   * We can either get one original or multiple orinals from the popup
   * We can use that data to show oringlas
   * But for now based on description type selected, I will use either one or multiple
   * originals. This should be changed later on.
   */
  const [originals, setOriginals] = useState([]);
  const [multipleReplacements, setMultipleReplacements] = useState([
    { id: 1, text: '', editable: true },
  ]);
  const [singleReplacementText, setSingleReplacementText] = useState('');
  const [replacementsValidationResults, setReplacementsValidationResults] = useState(undefined)
  const [showValidationModal, setShowValidationModal] = useState(false)
  const [canValidateMultipleReplacements, setCanValidateMulitpleReplacements] = useState(false);
  const [reasonOrDescription, setReasonOrDescription] = useState('');

  /**
   * individualEmailsList is used to track comma seperated emails entered
   * inside individualEmails box below choose mailing lists
   */

  const [devDetails, setDevDetails] = useState([])
  const [formulaSteps, setFormulaSteps] = useState([]);
  const [showAdditionalComponents, setShowAdditionalComponents] = useState(false)
  const [includesAZCWP, setIncludesAZCWP] = useState(false)
  const [acceptedTerms, setAcceptedTerms] = useState(false)

  let today = new Date();
  let days = deviationType === 'relabel' ? 365 : 90
  const maxDate = today.setDate(today.getDate() + days)

  //If we have received the details from clone option we need to use those values
  useEffect(() => {
    let details;
    if (deviationDetails !== "") {
      //This is called when we got details from clone option
      const { materialNumber, sapMaterialNumber, replacementSapMaterialNumber, plantName, description, plantCode, npdNumber, beginDate, endDate, recipeStepName, deviationValue } = deviationDetails
      console.log('date', new Date(beginDate));
      details = {
        headerMaterial: materialNumber,
        plants: [plantCode + " " + plantName],
        originals: [{ title: deviationType === "component" || deviationType === "relabel" ? sapMaterialNumber : recipeStepName, description: '', allergens: [], mayContain: [], materialType: "" }],
      };
      setOriginals([{ title: deviationType === "component" || deviationType === "relabel" ? sapMaterialNumber : recipeStepName, description: '', allergens: [], mayContain: [], materialType: "" }]);
      setOriginalsChosen(true)
      setSingleReplacementText(deviationType === "component" || deviationType === "relabel" ? replacementSapMaterialNumber : deviationValue);
      setReasonOrDescription(description)
      setNpdNumber(npdNumber)
      npdNumber ? setSpecChgReq(true) : setSpecChgReq(false)
      setStartDate(new Date(beginDate.split('T')[0]));
      setEndDate(new Date(endDate.split('T')[0]));
      setReplacementsValid(true)
      setReplacementsValidated(true)
      setDevDetails(details);
    }
    else {
      //We have recieved details starting from scratch
      details = {
        headerMaterial: materialNumber,
        plants: plants
      };
      if (deviationType === 'relabel') {
        setOriginals([{ title: materialNumber, description: '', allergens: [], mayContain: [], materialType: '' }])
        setOriginalsChosen(true)
      } else {
        setOriginals([])
        setOriginalsChosen(false)
      }
      setDevDetails(details);
      setSingleReplacementText('')
      setReasonOrDescription('')
      setNpdNumber('')
      setStartDate(new Date())
      setEndDate(new Date())
      setReplacementsValidationResults(undefined)
      setReplacementsValidated(false)
      setReplacementsValid(false)
    }

  }, [])

  useEffect(() => {
    if (deviationDetails) {
      if (!originals || originals.length === 0) return
      console.log("deviationDetails.sapMaterialNumber: ", deviationDetails.sapMaterialNumber)
      console.log("originals[0].title: ", originals[0].title)
      if (deviationType === "component" || deviationType === "relabel") {
        if (deviationDetails.sapMaterialNumber !== originals[0].title) {
          setReplacementsValidated(false);
          setReplacementsValid(false);
        }
      }
    } else {
      setReplacementsValidated(false);
      setReplacementsValid(false);
    }
    setOriginalsChosen(true)
    if (!originals || originals.length === 0) {
      setOriginalsChosen(false)
    }
    console.log("originals: ", originals)
  }, [originals])

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (endDate < date) setEndDate(date);
  };

  useEffect(() => {
    setLoading(true);
    console.log("Official product: ", officialProductDetails);
    console.log("zcwp details: ", zwcpDetails);
    if (officialProductDetails) {
      setIngredientStatement(officialProductDetails.IngredientStatement)
      let auxFormulaSteps = officialProductDetails.FormulaSteps.FormulaStep;
      // If it is a single element wrap it as an array
      if (Object.keys(officialProductDetails.FormulaSteps.FormulaStep).includes("Code")) {
        auxFormulaSteps = [officialProductDetails.FormulaSteps.FormulaStep]
      }
      const auxPackingMaterial = officialProductDetails.PackagingMaterials.PackagingMaterialDetail;
      console.log("auxFormulaSteps: ", auxFormulaSteps)
      let getFormulaSteps = [];
      let contents = []
      auxFormulaSteps.forEach((step, index) => {
        console.log("step: ", step)
        if (deviationType !== "process") {
          contents = []
          if (step.InputItems?.InputDetail?.length > 0) {
            step.InputItems?.InputDetail?.forEach(item => {
              let legacyProfile = item.CrossReferences?.LegacyProfile?.filter(legProf => legProf.SystemId === "SAP4MM")
              let sapMaterialNum = legacyProfile ? legacyProfile[0].Equivalent : ""
              let allergens = item.Allergens.string ? item.Allergens.string.length > 0 ? item.Allergens.string : [] : []
              contents.push({ title: sapMaterialNum, description: item.SpecName, allergens, materialType: item.SapMaterialType })
              if (deviationDetails?.sapMaterialNumber === sapMaterialNum) {
                setOriginals([{ title: deviationDetails.sapMaterialNumber, description: item.SpecName, allergens, materialType: item.SapMaterialType }]);
              }
            })
          } else {
            let legacyProfile = step.InputItems?.InputDetail?.CrossReferences?.LegacyProfile?.filter(legProf => legProf.SystemId === "SAP4MM")
            let sapMaterialNum = legacyProfile ? legacyProfile[0].Equivalent : ""
            let allergens = step.InputItems.InputDetail.Allergens.string ? step.InputItems.InputDetail.Allergens.string.length > 0 ? step.InputItems.InputDetail.Allergens.string : [] : []
            contents.push({ title: sapMaterialNum, description: step.InputItems.InputDetail.SpecName, allergens, materialType: step.InputItems.InputDetail.SapMaterialType })
            if (deviationDetails?.sapMaterialNumber === sapMaterialNum) {
              setOriginals([{ title: deviationDetails.sapMaterialNumber, description: step.InputItems.InputDetail.SpecName, allergens, materialType: step.InputItems.InputDetail.SapMaterialType }]);
            }
          }

          getFormulaSteps.push({ title: step.Name + " - " + step.Code, contents });
        } else {
          if (deviationDetails?.recipeStepName === step.Name) {
            setOriginals([{ title: deviationDetails.recipeStepName, description: step.Code, allergens: [], mayContain: [], materialType: "" }]);
          }
          contents.push({ title: step.Name, description: step.Code, allergens: [], mayContain: [], materialType: "" })
          if (index === auxFormulaSteps.length - 1) {
            contents.push({ title: "Packing", description: "", allergens: [], mayContain: [], materialType: "" })
            getFormulaSteps.push({ title: "Processes", contents })
          }
        }
      })

      let auxArray = [];
      if (deviationType !== "process") {
        if (auxPackingMaterial?.length > 0) {
          auxPackingMaterial?.forEach(el => {
            auxArray.push({ title: el.SapMaterialNumber, description: el.SpecName })
          })
        } else if (auxPackingMaterial) {
          auxArray.push({ title: auxPackingMaterial.SapMaterialNumber, description: auxPackingMaterial.SpecName })
        }

        getFormulaSteps.push({ title: "Packing", contents: auxArray });
      }
      setFormulaSteps(getFormulaSteps);
    } else if (zwcpDetails) {
      let getFormulaSteps = [];
      let auxArray = [];
      zwcpDetails.forEach(el => {
        console.log("el.Allergen: ", el.Allergen)
        auxArray.push({ title: el.Component, description: el.Descr, allergens: el.Allergen ?? [], materialType: el.Mat_type });
      })
      getFormulaSteps.push({ title: zwcpDetails[0].Plant, contents: auxArray });
      setFormulaSteps(getFormulaSteps);
      console.log(getFormulaSteps);
    } else {
      swal.fire({
        icon: 'warning',
        title: 'No data found for material number',
        timer: 3000,
      });
    }
    setLoading(false);
  }, []);

  const handleEndDateChange = (date) => {
    setEndDate(date);
    //console.log(date);
  };

  const handleSpecChgChange = (e) => {
    console.log('e.target.checked: ', e.target.checked);
    if (e.target.checked === false) {
      setNpdNumber('');
    }
    setSpecChgReq(e.target.checked);
  };

  const handleNpdNumberChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setNpdNumber(e.target.value);
    }
  };

  useEffect(() => {
    const newDate = new Date(startDate);
    newDate.setDate(startDate.getDate() + 90);
    setTimeFrame(newDate);
  }, [startDate]);

  const originalsChosen = () => {
    setOriginalsChosen(true);
  };

  const handleReplaceInput = (e) => {
    if (deviationType === "component" || deviationType === "relabel") {
      const re = /^[0-9\b]+$/;
      if (e.target.value === '' || re.test(e.target.value)) {
        setSingleReplacementText(e.target.value);
      }
    }

    if (deviationType === "process") {
      if (e.target.value !== "") {
        setReplacementsValid(true)
        setReplacementsValidated(true)
      } else {
        setReplacementsValid(false)
        setReplacementsValidated(false)
      }
      setSingleReplacementText(e.target.value);
    }

  };

  const handleValidateReplacements = () => {
    /**
     * if the describe type is 2 then multiple replacements validation otherwise
     * single replacement validation. this needs to be taken care later.
     * But for now clicking on validate button I am changing replacementsValidated to true
     */

    /**
     * 1. Once the replacements are validated we will have show change replacements.
     * 2. Shold make multiple replacements last object to non editable so that add more button
     * will be removed
     */
    setAcceptedTerms(false)
    if (!zwcpDetails) setIncludesAZCWP(false)
    // Validate allergens is still missing but will go here
    // describeType 1 or 3 single replacement
    if (describeType !== "2") {
      setLoading(true)
      onValidateDeviationMaterial(plantCodes, [singleReplacementText], originals, zwcpDetails ? true : false).then(result => {
        console.log("result.data: ", result.data)
        setLoading(false)
        setReplacementsValidated(false)
        setReplacementsValid(false)

        if (typeof result === "string") {
          console.log("validate deviation material error: ", result)
          swal.fire({
            icon: "warning",
            title: "No data returned from service!",
          })
          return
        }

        if (!result) {
          setLoading(false)
          setReplacementsValidated(false)
          setReplacementsValid(false)
          console.log("validate deviation material error: no result")
          swal.fire({
            icon: "warning",
            title: "No data returned from service!",
          })
          return
        }

        if (result?.data && result?.data?.isSuccess === false) {
          setLoading(false)
          setReplacementsValidated(false)
          setReplacementsValid(false)
          console.log("validate deviation material error: ", result.data)
          swal.fire({
            icon: "warning",
            title: "No data returned from service!",
          })
          return
        }

        let allReplacementsValid = true
        let tempValidationResults = []
        let validationResults = result.data.data
        validationResults.forEach(material => {
          if (!material.validMaterial || !material.validPlant || !material.validAllergens || !material.compatibleMaterialTypes) {
            allReplacementsValid = false
          }
          if (material?.originalMaterialType === "ZCWP" || material?.replacementMaterialType === "ZCWP") setIncludesAZCWP(true)
          tempValidationResults.push({
            materialNumber: showAdditionalComponents ? material.material + " - " + material.original : material.material,
            valid: material.validPlant && material.validMaterial && material.validAllergens && material.compatibleMaterialTypes,
            validPlant: material.validPlant,
            validMaterial: material.validMaterial,
            validAllergen: material.validAllergens,
            compatibleMaterialTypes: material.compatibleMaterialTypes,
            description: material.description
          })
        })

        setLoading(false)
        setReplacementsValidationResults(tempValidationResults)
        setReplacementsValidated(true)
        setReplacementsValid(allReplacementsValid)
        setShowValidationModal(true)
      })
    }
    //singleReplacementText
    // describeType 2 multiple replacements
    if (describeType === "2") {
      const tempMultipleReplacements = [...multipleReplacements];
      tempMultipleReplacements[
        tempMultipleReplacements.length - 1
      ].editable = false;
      setMultipleReplacements(tempMultipleReplacements);

      let allReplacementsValid = true
      let tempValidationResults = []
      setLoading(true)
      const replacementNumbers = multipleReplacements.map(rep => rep.text)
      onValidateDeviationMaterial(plantCodes, replacementNumbers, originals, zwcpDetails ? true : false).then(result => {
        if (typeof result === "string") {
          setLoading(false)
          setReplacementsValidated(false)
          setReplacementsValid(false)
          console.log("validate deviation material error: ", result)
          swal.fire({
            icon: "warning",
            title: "No data returned from service!",
          })
          return
        }

        if (!result) {
          setLoading(false)
          setReplacementsValidated(false)
          setReplacementsValid(false)
          console.log("validate deviation material error: no result")
          swal.fire({
            icon: "warning",
            title: "No data returned from service!",
          })
          return
        }

        if (result?.data && result?.data?.isSuccess === false) {
          setLoading(false)
          setReplacementsValidated(false)
          setReplacementsValid(false)
          console.log("validate deviation material error: ", result.data)
          swal.fire({
            icon: "warning",
            title: "No data returned from service!",
          })
          return
        }

        let validationResults = result.data.data
        validationResults.forEach(material => {
          if (!material.validMaterial || !material.validPlant || !material.validAllergens || !material.compatibleMaterialTypes) {
            allReplacementsValid = false
          }
          if (material?.originalMaterialType === "ZCWP" || material?.replacementMaterialType === "ZCWP") setIncludesAZCWP(true)
          tempValidationResults.push({
            materialNumber: showAdditionalComponents ? material.material + " - " + material.original : material.material,
            valid: material.validPlant && material.validMaterial && material.validAllergens && material.compatibleMaterialTypes,
            validPlant: material.validPlant,
            validMaterial: material.validMaterial,
            validAllergen: material.validAllergens,
            compatibleMaterialTypes: material.compatibleMaterialTypes,
            description: material.description
          })

        })
        setLoading(false)
        setReplacementsValidationResults(tempValidationResults)
        setReplacementsValidated(true)
        setReplacementsValid(allReplacementsValid)
        setShowValidationModal(true)

      })
    }
  };

  const onValidationClose = (editValid) => {
    setShowValidationModal(false)
    if (describeType === "2") {
      if (!replacementsValidationResults) return
      let editableReplacements = [...multipleReplacements]
      let tempValidationResults = [...replacementsValidationResults]
      editableReplacements.forEach(edRep => {
        tempValidationResults.forEach(rep => {
          console.log("rep: ", rep)
          if (edRep.text === rep.materialNumber) {
            if (rep.valid) {
              edRep.editable = editValid ? true : false
            } else {
              edRep.editable = true
            }
          }
        })
      })
      setMultipleReplacements(editableReplacements);
      if (editValid) {
        setReplacementsValidated(false)
        setReplacementsValid(false)
      }
    }
    if (describeType !== "2") {
      if (!replacementsValidationResults) return
      for (let i = 0; i < replacementsValidationResults.length; i++) {
        if (!replacementsValidationResults[i].valid) {
          setSingleReplacementText('')
          break;
        }
      }
      if (editValid) {
        setReplacementsValidated(false)
        setReplacementsValid(false)
      }
    }
  }

  const handleChangeReplacements = () => {
    /**
     * 1. Clicking on change replacements, we will need to empty data in the
     * singleReplacementText, multipleReplacements
     * based on describeType
     */
    console.log("describeType: ", describeType)
    console.log("describeType == 2: ", describeType == 2)
    if (describeType == 2) {
      console.log("asdfasdf")
      let tempMultipleReplacements = [...multipleReplacements];
      tempMultipleReplacements = [{ id: 1, text: '', editable: true }];
      setMultipleReplacements(tempMultipleReplacements);
    } else {
      setSingleReplacementText('');
    }
    setReplacementsValidated(false);
    setReplacementsValid(false)
  };

  useEffect(() => {
    let totalCharsLength = 0;
    if (multipleReplacements.length > 1) {
      multipleReplacements.forEach((replacement) => {
        totalCharsLength = totalCharsLength + replacement.text.length;
      });
    } else {
      totalCharsLength = multipleReplacements[0]?.text?.length;
    }

    if (totalCharsLength >= multipleReplacements.length) {
      setCanValidateMulitpleReplacements(true);
    } else {
      setCanValidateMulitpleReplacements(false);
    }
  }, [multipleReplacements]);

  const updateReplacements = (replacements) => {
    console.log('replacements', replacements);
    if (deviationType === "process") {
      let emptyReplacements = replacements.filter(rep => rep.text === "")
      if (emptyReplacements.length > 0) {
        setReplacementsValid(false)
        setReplacementsValidated(false)
      } else {
        setReplacementsValid(true)
        setReplacementsValidated(true)
      }
    }
    setMultipleReplacements(replacements);
  };

  const handleReasonOrDescription = (e) => {
    const reasonValue = e.target.value;
    console.log('reasonValue', reasonValue);
    setReasonOrDescription(reasonValue);
  };

  const saveHandler = (review) => {
    return new Promise((resolve, reject) => {
      if (!areVerified) {
        swal.fire({
          icon: "warning",
          title: "Please fill mandatory fields",
          timer: 3000,
        })
        resolve(false)
        return
      }

      let correctedDescribeType = 0

      switch (describeType) {
        case "1":
          correctedDescribeType = 0
          break;
        case "2":
          correctedDescribeType = 1
          break;
        case "3":
          correctedDescribeType = 2
          break;
        default:
          correctedDescribeType = 0
          break;
      }

      let deviationData = {
        materialNumber: devDetails.headerMaterial,
        originals: deviationType === "component" || deviationType === "relabel" ? showAdditionalComponents ? originals.map(original => original.title + "-" + original.plant).join(',') : originals.map(original => original.title).join(',') : "",
        replacements: correctedDescribeType !== 1 ? singleReplacementText : multipleReplacements.map(replacement => replacement.text).join(','),
        createUser: userInfo?.name,
        beginDate: moment.utc(startDate).tz("America/Chicago").format("YYYY-MM-DD"),
        endDate: moment.utc(endDate).tz("America/Chicago").format("YYYY-MM-DD"),
        description: reasonOrDescription,
        type: deviationType,
        specChange: specChgReq ? 1 : 0,
        npdNumber,
        ecmNumber: "",
        userEmail: userInfo?.email,
        plantCode: deviationDetails ? deviationDetails.plantCode : plantCodes.join(','),
        status: "EDIT",
        multipleType: correctedDescribeType,
        recipeStepName: deviationType === "component" || deviationType === "relabel" ? "" : showAdditionalComponents ? originals.map(original => original.title + "-" + original.plant).join(',') : originals.map(original => original.title).join(','),
        deviationValue: deviationType === "component" || deviationType === "relabel" ? "" : correctedDescribeType !== 1 ? singleReplacementText : multipleReplacements.map(replacement => replacement.text).join(','),
        deletedAt: null
      }
      console.log("deviationData: ", deviationData)
      setLoading(true)
      onSaveDeviation(deviationData, showAdditionalComponents).then(result => {
        if (!showAdditionalComponents) {
          console.log("result: ", result)
          if (typeof result === "string" || !result) {
            setLoading(false)
            swal.fire({
              icon: "error",
              title: "Error returned from service",
              text: result ?? ""
            })
            resolve(false)
            return
          }
          console.log("result.data.isSuccess: ", result?.data?.isSuccess)
          if (!result?.data?.isSuccess) {
            console.log("result.data.data.error", result.data.data.error)
            setLoading(false)
            swal.fire({
              icon: "error",
              title: "Could not save deviation(s)",
              text: result.data.data.error?.message,
            })
            resolve(false)
            return
          }
          setLoading(false)
          swal.fire({
            icon: "success",
            title: "Deviation(s) successfully saved!"
          })
          if (!review) {
            handleBottomSheetClose()
          }
          resolve(true)
        } else {
          console.log("result: ", result)
          if (typeof result === "string" || !result) {
            setLoading(false)
            swal.fire({
              icon: "error",
              title: "Error returned from service",
              text: result ?? ""
            })
            resolve(false)
            return
          }
          console.log("result.data.isSuccess: ", result?.data?.isSuccess)
          let allSuccess = true
          let errorCount = 0
          let message = ""
          result.data.data.forEach(saveResult => {
            if (!saveResult.isSuccess) {
              errorCount++
              allSuccess = false
              message += message === "" ? saveResult.data.error.message : ", " + saveResult.data.error.message
            } else {
              message += message === "" ? "Deviation: " + saveResult.data.newDeviationIds + " saved!" : ", Deviation: " + saveResult.data.newDeviationIds + " saved!"
            }
          })
          setLoading(false)
          swal.fire({
            icon: allSuccess ? "success" : errorCount === result.data.data.length ? "error" : "warning",
            title: allSuccess ? "Deviation(s) successfully saved!" : errorCount === result.data.data.length ? "Deviation(s) not saved!" : "Some deviation(s) weren't saved",
            text: message
          })

          if (errorCount === result.data.data.length) {
            resolve(false)
            return
          }
          resolve(true)
          if (!review) {
            handleBottomSheetClose()
          }
        }
      })
    })

  }

  const saveAndReviewHandler = () => {
    saveHandler(true).then((res) => {
      if (res) {
        setCurrentStage("CONTACT_INFO")
      }
    })
  }


  useEffect(() => {
    /**
     * 1. Atleast one in emailsSelection or individuals emails box should have data
     * 2. If one of the email is selected from emails list but no data is entered in
     * the individual box then it is valid
     * 3. If one of the email is selected from emails list but an invalid mail list is
     * entered in the individual box then it is invalid
     * 4. If nothing is selected from the emails list but valid mail is entered in the
     * individual box then it is valid
     */


    if (
      replacementsValidated &&
      replacementsValid &&
      reasonOrDescription
    ) {
      setAreVerified(true);
    } else {
      setAreVerified(false);
    }
  }, [replacementsValidated, replacementsValid, reasonOrDescription]);

  // const plants = plants
  //   ? plants.map((plant, index) => {
  //     return (
  //       <TextField
  //       key={index + " " + plant}
  //         size="small"
  //         variant="outlined"
  //         value={plant}
  //         disabled
  //         className="pb-1"
  //       />
  //     );
  //   })
  //   : undefined;

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Row>
              <FormLabel component="legend" className="labelHeader">
                Header material and Plant(s)
              </FormLabel>
              <Col className="text-right pr-1">
                <FormLabel component="span" className="fontSizeMobile">
                  Header material:
                </FormLabel>
              </Col>
              <Col className="pl-0">
                <TextField
                  size="small"
                  variant="outlined"
                  value={
                    Object.entries(devDetails).length > 0
                      ? devDetails.headerMaterial
                      : ''
                  }
                  disabled
                  className="formInput pb-1"
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className="text-right pr-1">
                <FormLabel component="span" className="fontSizeMobile">
                  Plant(s):
                </FormLabel>
              </Col>
              <Col className="pl-0">
                {Object.entries(devDetails).length > 0
                  ? devDetails.plants.map((plant, index) => (
                    <TextField
                      key={index + " " + plant}
                      size="small"
                      variant="outlined"
                      value={plant}
                      disabled
                      className="pb-1"
                    />
                  ))
                  : ''}
              </Col>
            </Row>

            <Row>
              <FormLabel component="legend" className="labelHeader">
                Dev Details
              </FormLabel>
              <Col className="text-right pr-1">
                <FormLabel component="span" className="fontSizeMobile" required>
                  Original:
                </FormLabel>
              </Col>
              {!isOriginalsChosen ? (
                <Col className="pl-0 text-left">
                  <u
                    style={{ cursor: 'pointer', fontSize: '14px' }}
                    onClick={() => setShowOriginals(true)}
                  >
                    Click to choose
                  </u>
                </Col>
              ) : (
                <Col className="pl-0 text-left">
                  {Object.entries(devDetails).length > 0 &&
                    originals.map((original, index) => (
                      <Col
                        key={index}
                        style={{
                          padding: '0px',
                          margin: '0px',
                          width: '100%',
                        }}
                      >
                        <DisabledText
                          text={original.title}
                          style={{ width: '100%', marginBottom: '5px' }}
                        />
                        <div style={{ fontSize: '14px', color: '#A0A0A0' }}>
                          <small>
                            {original.description}
                          </small>
                        </div>
                      </Col>
                    ))}
                  {deviationType !== 'relabel' ? <Col
                    style={{
                      marginBottom: '10px',
                      marginTop: '5px',
                      paddingLeft: '0px',
                      fontSize: '14px',
                    }}
                  >
                    <u
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowOriginals(true)}
                    >
                      Change Original(s)
                    </u>
                  </Col> : undefined}
                </Col>
              )}
            </Row>
            {isOriginalsChosen ? (
              describeType === "2" ? (
                <Row>
                  <Col className="text-right pr-1">
                    <FormLabel
                      component="span"
                      className="fontSizeMobile"
                      required
                    >
                      Replacement:
                    </FormLabel>
                  </Col>
                  <Col className="text-left pl-0">
                    <MultipleReplacement
                      replacementsValid={replacementsValid}
                      multipleReplacements={multipleReplacements}
                      deviationType={deviationType}
                      // setMultipleReplacements={setMultipleReplacements}
                      updateReplacements={updateReplacements}
                      replacementsValidated={replacementsValidated}
                      replacementsValidationResults={replacementsValidationResults}
                    />
                    {replacementsValidated && replacementsValid ? (
                      <Col
                        style={{
                          marginBottom: '10px',
                          marginTop: '5px',
                          textAlign: 'start',
                          paddingLeft: '0px',
                        }}
                      >
                        <u
                          style={{ cursor: 'pointer', fontSize: '14px' }}
                          onClick={() => handleChangeReplacements()}
                        >
                          Change Replacement(s)
                        </u>
                      </Col>
                    ) : null}
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col className="text-right pr-1">
                    <FormLabel
                      component="span"
                      className="fontSizeMobile"
                      required
                    >
                      Replacement:
                    </FormLabel>
                  </Col>
                  <Col className="text-left pl-0">
                    <TextField
                      size="small"
                      variant="outlined"
                      value={singleReplacementText}
                      type={deviationType === "component" || deviationType === "relabel" ? "tel" : ""}
                      inputProps={{
                        maxLength: deviationType === "component" || deviationType === "relabel" ? 11 : 100,
                      }}
                      onChange={(e) => handleReplaceInput(e)}
                      className="inputStyle"
                      disabled={replacementsValidated && replacementsValid && (deviationType === "component" || deviationType === "relabel") ? true : false}
                    />
                    <FormLabel component="span" className="labelNote">
                      (Type in a replacement component id, numbers only)
                    </FormLabel>
                    {/* Code commented to show the description of replacement*/}
                    <div style={{ color: '#A0A0A0' }}><small>{replacementsValidationResults?.length > 0 && replacementsValid ? replacementsValidationResults[0]?.description : ""}</small></div>
                    {replacementsValidated && replacementsValid ? (
                      <Col
                        style={{
                          marginBottom: '10px',
                          marginTop: '5px',
                          textAlign: 'start',
                          paddingLeft: '0px',
                        }}
                      >
                        <u
                          style={{ cursor: 'pointer', fontSize: '14px' }}
                          onClick={() => handleChangeReplacements()}
                        >
                          Change Replacement(s)
                        </u>
                      </Col>
                    ) : null}
                  </Col>
                </Row>
              )
            ) : null}

            {isOriginalsChosen && (deviationType === "component" || deviationType === "relabel") && (!replacementsValidated || !replacementsValid) ? (
              describeType === "2" ? (
                <Row
                  className="m-2"
                  style={{ alignItems: 'center', justifyContent: 'center' }}
                >
                  <OutLineButton
                    text="Validate Replacement(s)"
                    backgroundColor="#65686B"
                    isClickable={canValidateMultipleReplacements}
                    onClick={handleValidateReplacements}
                  />
                </Row>
              ) : (
                <Row
                  className="m-2"
                  style={{ alignItems: 'center', justifyContent: 'center' }}
                >
                  <OutLineButton
                    text="Validate Replacement(s)"
                    backgroundColor="#65686B"
                    onClick={handleValidateReplacements}
                    isClickable={
                      singleReplacementText.length > 0 ? true : false
                    }
                  />
                </Row>
              )
            ) : null}
            {zwcpDetails && isOriginalsChosen ? <p style={{ color: "red" }}>Caution! ZCWP's allergens aren't validated</p> : undefined}
            <Row>
              <Col className="text-right pr-1">
                <FormLabel component="span" className="fontSizeMobile" required>
                  Dev Begin Date:
                </FormLabel>
              </Col>
              <Col className="text-left pl-0 pb-1">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disablePast
                    disableToolbar
                    className="inputStyle"
                    inputFormat="MM/DD/YYYY"
                    value={startDate ? dayjs(startDate) : null}
                    onChange={handleStartDateChange}
                    maxDate={maxDate ? dayjs(maxDate) : null}
                    renderInput={(params) => <TextField {...params} variant="outlined" InputAdornmentProps={{ position: 'end' }} />}
                  />
                </LocalizationProvider>
              </Col>
            </Row>
            <Row>
              <Col className="text-right pr-1">
                <FormLabel component="span" className="fontSizeMobile" required>
                  End Date:
                </FormLabel>
              </Col>
              <Col className="text-left pl-0 pb-1">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disablePast
                    disableToolbar
                    className="inputStyle"
                    inputFormat="MM/DD/YYYY"
                    value={endDate ? dayjs(endDate) : null}
                    onChange={handleEndDateChange}
                    maxDate={maxDate ? dayjs(maxDate) : null}
                    renderInput={(params) => <TextField {...params} variant="outlined" InputAdornmentProps={{ position: 'end' }} />}
                  />
                </LocalizationProvider>
              </Col>
            </Row>
            <Row>
              <Col className="text-right pr-1">
                <FormLabel component="span" className="fontSizeMobile" required>
                  Reason/Description:
                </FormLabel>
              </Col>
              <Col className="pl-0 pb-1 text-left">
                <TextField
                  multiline
                  value={reasonOrDescription}
                  onChange={handleReasonOrDescription}
                  rows={4}
                  inputProps={{
                    maxLength: 1000,
                  }}
                  variant="outlined"
                  className="inputStyle"
                />
                <span className="fontSizeMobile">(Upto 1,000 characters)</span>
              </Col>
            </Row>
            {userInfo?.role === 'CORP' || userInfo?.role === "ADMIN" ? (
              <FormGroup style={{ marginBottom: '0.5rem' }}>
                <FormControlLabel
                  component="legend"
                  control={
                    <Checkbox
                      onChange={(e) => handleSpecChgChange(e)}
                      color="default"
                    />
                  }
                  checked={specChgReq}
                  label="Spec change required"
                />
              </FormGroup>
            ) : undefined}
            {specChgReq === true ? (
              <Row>
                <Col className="text-right pr-1">
                  <FormLabel component="span" className="fontSizeMobile">
                    Npd number:
                  </FormLabel>
                </Col>
                <Col className="pl-0 pb-1">
                  <TextField
                    size="small"
                    variant="outlined"
                    className="inputStyle"
                    value={npdNumber}
                    onChange={(e) => handleNpdNumberChange(e)}
                  />
                </Col>
              </Row>
            ) : undefined}

            <Row className="pb-3">
              <Col>
                <Button
                  size="small"
                  variant="outlined"
                  disabled={!areVerified}
                  className={areVerified ? 'darkGrayColor' : 'lightGrayColor'}
                  onClick={() => saveHandler(false)}
                >
                  Save
                </Button>
              </Col>

              <Col>
                <Button
                  size="small"
                  variant="outlined"
                  disabled={!areVerified}
                  className={areVerified ? 'darkGrayColor' : 'lightGrayColor'}
                  onClick={saveAndReviewHandler}
                >
                  Save and Review
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        {showOriginals ? <ChooseOriginals
          officialProductDetails={officialProductDetails}
          zwcpDetails={zwcpDetails}
          showSteps={showOriginals}
          closeSteps={setShowOriginals}
          originalsChosen={originalsChosen}
          setOriginals={setOriginals}
          formulaSteps={formulaSteps}
          canSelectMultiple={describeType === "3" ? true : false}
          size={"sm"}
          plantCodes={plantCodes}
          userInfo={userInfo}
          showAdditionalComponents={showAdditionalComponents}
          setShowAdditionalComponents={setShowAdditionalComponents}
          additionalComponents={additionalComponents}
          onGetAdditionalComponents={onGetAdditionalComponents}
          materialNumber={materialNumber}
          setLoading={setLoading}
          onSetAdditionalComponents={onSetAdditionalComponents}
          deviationType={deviationType}
        /> : undefined}
      </Container>
      {showValidationModal && replacementsValidationResults ? <ValidateReplacementModal
        open={showValidationModal}
        onClose={onValidationClose}
        replacements={replacementsValidationResults}
        replacementsValid={replacementsValid}
        acceptedTerms={acceptedTerms}
        setAcceptedTerms={setAcceptedTerms}
        includesAZCWP={includesAZCWP}
      /> : undefined}
    </>
  );
};

export default DeviationRequestMobile;

import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Button from '@mui/material/Button';
import swal from 'sweetalert2';
import moment from 'moment-timezone'
import Media from 'react-media';
import TitleText from '../titleText/TitleText';
import ContactInfoData from './contactInfoData/ContactInfoData';
import DevDetailsRequest from './devDetailsRequest/DevDetailsRequest';
import './DeviationRequest.css';

const DeviationRequest = (props) => {
  const { deviationType,
    describeType,
    plants,
    plantCodes,
    materialNumber,
    deviationDetails,
    onValidateDeviationMaterial,
    onGetDistributionLists,
    userInfo,
    onSaveDeviation,
    lastSavedDeviations,
    handleStartOver,
    onStatusChange,
    officialProductDetails,
    zwcpDetails,
    onUpdateRequest,
    additionalComponents,
    onGetAdditionalComponents,
    onSetAdditionalComponents,
    setLoading,
  } = props;

  const [areVerified, setAreVerified] = useState(false);
  //We can start showing save, save and review buttons as soon as replacements were choosen
  const [canShowSaveButtons, setCanShowSaveButtons] = useState(false);
  const [onSaveAndReview, setOnSaveAndReview] = useState(false)
  const [distributionLists, setDistributionLists] = useState([])
  const [approversSelected, setApproversSelected] = useState([])
  const [individualEmailsList, setIndividualEmailsList] = useState('')
  const [ccIndividualEmailsList, setccIndividualEmailsList] = useState('')
  const [ccSelected, setCCSelected] = useState([])
  const [contactInfoVerified, setContactInfoVerified] = useState(false)
  const [startDate, setStartDate] = useState(new Date());
  const [ingredientStatement, setIngredientStatement] = useState('')
  const [endDate, setEndDate] = useState(new Date());
  const [reasonOrDescription, setReasonOrDescription] = useState('');
  const [devDetails, setDevDetails] = useState([]);
  const [specChgReq, setSpecChgReq] = useState(false);
  const [originals, setOriginals] = useState([]);
  const [multipleReplacements, setMultipleReplacements] = useState([{ id: 1, text: '', editable: true },]);
  const [singleReplacementText, setSingleReplacementText] = useState('');
  const [npdNumber, setNpdNumber] = useState('');
  const [showAdditionalComponents, setShowAdditionalComponents] = useState(false)

  useEffect(() => {
    let plantCodes_ = deviationDetails ? [deviationDetails.plantCode] : plantCodes
    if (!plantCodes_ || plantCodes_.length === 0) return
    onGetDistributionLists(plantCodes_).then(result => {
      if (!result?.data?.isSuccess) {
        swal.fire({
          icon: "warning",
          title: "No distribution lists found for plants!",
          timer: 3000,
        })
        return
      }
      if (!result?.data?.data.length === 0) {
        swal.fire({
          icon: "warning",
          title: "No distribution lists found for plants!",
          timer: 3000,
        })
        return
      }
      let distributionLists = result.data.data
      setDistributionLists(distributionLists)
    })
  }, [deviationDetails, plantCodes])

  useEffect(() => {
    let approvers = []
    if (approversSelected.length === 0) {
      distributionLists.forEach(dl => {
        approvers.push({ ...dl, selected: true })
      })
      setApproversSelected(approvers)
    }
    let cc = []
    if (ccSelected.length === 0) {
      distributionLists.forEach(dl => {
        cc.push({ ...dl, selected: false })
      })
      setCCSelected(cc)
    }
  }, [distributionLists])


  // Save will create the deviation on edit status without selecting emails through the saveDeviation API, it should return to show main screen
  const saveHandler = (review) => {
    return new Promise((resolve, reject) => {
      if (!areVerified) {
        swal.fire({
          icon: "warning",
          title: "Please fill mandatory fields",
          timer: 3000,
        })
        resolve()
        return
      }

      let correctedDescribeType = 0

      switch (describeType) {
        case "1":
          correctedDescribeType = 0
          break;
        case "2":
          correctedDescribeType = 1
          break;
        case "3":
          correctedDescribeType = 2
          break;
        default:
          correctedDescribeType = 0
          break;
      }

      let deviationData = {
        materialNumber,
        originals: deviationType === "component" || deviationType === "relabel" ? showAdditionalComponents ? originals.map(original => original.title + "-" + original.plant).join(',') : originals.map(original => original.title).join(',') : "",
        replacements: deviationType === "component" || deviationType === "relabel" ? correctedDescribeType !== 1 ? singleReplacementText : multipleReplacements.map(replacement => replacement.text).join(',') : "",
        createUser: userInfo?.name,
        beginDate: moment.utc(startDate).format("YYYY-MM-DD"),
        endDate: moment.utc(endDate).format("YYYY-MM-DD"),
        description: reasonOrDescription,
        type: deviationType,
        specChange: specChgReq ? 1 : 0,
        npdNumber,
        ecmNumber: "",
        userEmail: userInfo?.email,
        plantCode: deviationDetails ? deviationDetails.plantCode : plantCodes.join(','),
        status: "EDIT",
        multipleType: correctedDescribeType,
        recipeStepName: deviationType === "component" || deviationType === "relabel" ? "" : showAdditionalComponents ? originals.map(original => original.title + "-" + original.plant).join(',') : originals.map(original => original.title).join(','),
        deviationValue: deviationType === "component" || deviationType === "relabel" ? "" : correctedDescribeType !== 1 ? singleReplacementText : multipleReplacements.map(replacement => replacement.text).join(','),
        deletedAt: null
      }
      console.log("deviationData: ", deviationData)
      setLoading(true)
      onSaveDeviation(deviationData, showAdditionalComponents).then(result => {
        if (!showAdditionalComponents) {
          console.log("result: ", result)
          if (typeof result === "string" || !result) {
            setLoading(false)
            swal.fire({
              icon: "error",
              title: "Error returned from service",
              text: result ?? ""
            })
            resolve(false)
            return
          }
          console.log("result.data.isSuccess: ", result?.data?.isSuccess)
          if (!result?.data?.isSuccess) {
            console.log("result.data.data.error", result.data.data.error)
            setLoading(false)
            swal.fire({
              icon: "error",
              title: "Could not save deviation(s)",
              text: result.data.data.error?.message,
            })
            resolve(false)
            return
          }
          setLoading(false)
          swal.fire({
            icon: "success",
            title: "Deviation(s) successfully saved!"
          })
          resolve(true)
          if (!review) {
            setOnSaveAndReview(false)
            setCanShowSaveButtons(false)
            handleStartOver()
          }
        } else {
          console.log("result: ", result)
          if (typeof result === "string" || !result) {
            setLoading(false)
            swal.fire({
              icon: "error",
              title: "Error returned from service",
              text: result ?? ""
            })
            resolve(false)
            return
          }
          console.log("result.data.isSuccess: ", result?.data?.isSuccess)
          let allSuccess = true
          let errorCount = 0
          let message = ""
          result.data.data.forEach(saveResult => {
            if (!saveResult.isSuccess) {
              errorCount++
              allSuccess = false
              message += message === "" ? saveResult.data.error.message : ", " + saveResult.data.error.message
            } else {
              message += message === "" ? "Deviation: " + saveResult.data.newDeviationIds + " saved!" : ", Deviation: " + saveResult.data.newDeviationIds + " saved!"
            }
          })
          setLoading(false)
          swal.fire({
            icon: allSuccess ? "success" : errorCount === result.data.data.length ? "error" : "warning",
            title: allSuccess ? "Deviation(s) successfully saved!" : errorCount === result.data.data.length ? "Deviation(s) not saved!" : "Some deviation(s) weren't saved",
            text: message
          })

          if (errorCount === result.data.data.length) {
            resolve(false)
            return
          }
          resolve(true)
          if (!review) {
            setOnSaveAndReview(false)
            setCanShowSaveButtons(false)
            handleStartOver()
          }
        }
      })
    })
  }

  // Save and review will create the deviation on edit status and prompt the user to choose distribution lists or emails to submit the deviation and change it to pending status
  // While on save and review the Dev Details should be non editable
  const saveAndReviewHandler = () => {
    saveHandler(true).then(res => {
      if (res) {
        setOnSaveAndReview(true)
        setCanShowSaveButtons(false)
      }
    })
  }

  // The user should be able to edit the recently saved deviations, this process may become very complex should discuss this with the team
  // May be better to change it just to cancel and if clicked it'll redirect to main screen
  const cancelReview = () => {
    swal.fire({
      icon: "question",
      title: "Are you sure you want to cancel the review?",
      showCancelButton: true,
      confirmButtonText: "Continue reviewing",
    }).then(result => {
      if (!result.isConfirmed) {
        setOnSaveAndReview(false)
        setCanShowSaveButtons(false)
        handleStartOver()
      }
    })
  }

  // This should update the deviations to Pending status and send the emails to the selected emails or distribution lists
  const submitHandler = () => {
    console.log("submit it!")
    if (!contactInfoVerified) return
    let deviations = []
    let cc = ccSelected.filter(cc => cc.selected).map(cc => cc.name).join(",")
    let emailTo = approversSelected.filter(approver => approver.selected).map(app => app.name).join(",")
    lastSavedDeviations.forEach(deviation => deviations.push({ deviationId: deviation, statusChange: "PENDING", editUser: userInfo?.name, editReason: "Deviation Submitted", emailTo, cc, customEmailTo: individualEmailsList, customCC: ccIndividualEmailsList, ingredientStatement }))
    setLoading(true)
    onStatusChange(deviations).then(result => {
      if (typeof result === "string" || !result) {
        setLoading(false)
        swal.fire({
          icon: "error",
          title: "Error returned from service",
          text: result ?? ""
        })
        return
      }
      if (!result?.data?.isSuccess) {
        setLoading(false)
        swal.fire({
          icon: "error",
          title: "Could not submit deviation(s)",
          text: result.data.data.error?.message,
        })
        return
      }
      setLoading(false)
      let submittedDeviations = []
      let notSubmittedDeviations = []
      result.data.data.forEach(deviation => deviation.isSuccess ? submittedDeviations.push(deviation.data) : notSubmittedDeviations.push(deviation.data))
      let icon = notSubmittedDeviations.length > 0 && submittedDeviations.length > 0 ? "warning" : submittedDeviations.length === 0 ? "error" : "success"
      let message = ``
      submittedDeviations.forEach((deviation, index) => {
        if (index === 0) message += `The following deviation Id's were submitted: `
        message += index === submittedDeviations.length - 1 ? deviation : `${deviation}, `
        if (index === submittedDeviations.length) message += `</br>`
      })
      notSubmittedDeviations.forEach((deviation, index) => {
        if (index === 0) message += `The following deviation Id's were not submitted: `
        message += index === notSubmittedDeviations.length - 1 ? deviation : `${deviation}, `
      })
      swal.fire({
        icon,
        title: "Deviation(s) successfully submitted!",
        html: message
      })
      setOnSaveAndReview(false)
      setCanShowSaveButtons(false)
      handleStartOver()
    })
  }

  return (
    <Container fluid>
      <Col md={12} sm={6}>
        <Media
          queries={{
            mobile: '(max-width:425px)',
            desktop: '(min-width:426px)', //not specifying any max width...
          }}
        >
          {(matches) => (
            <>
              {matches.desktop && (
                <Row style={{ justifyContent: 'space-between' }}>
                  {/**
                   * Header information
                   */}
                  <TitleText text="component dev" />
                  <span>{onSaveAndReview ? "Saved, Not Submitted" : "Not Saved, Not Submitted"}</span>
                </Row>
              )}
              {matches.mobile && (
                <Col style={{ justifyContent: 'end', textAlign: 'right' }}>
                  {/**
                   * Header information
                   *
                   */}
                  {/* <div style={{textAlign:'right'}}> */}
                  <div className="title">{'component dev1'}</div>
                  <span>Not Saved, Not Submitted1</span>
                  {/* </div> */}
                </Col>
              )}
            </>
          )}
        </Media>
        <Row>
          <Col sm={12} md={6}>
            <DevDetailsRequest
              describeType={describeType}
              plants={plants}
              plantCodes={plantCodes}
              materialNumber={materialNumber}
              setAreVerified={setAreVerified}
              setCanShowSaveButtons={setCanShowSaveButtons}
              deviationDetails={deviationDetails}
              onValidateDeviationMaterial={onValidateDeviationMaterial}
              userInfo={userInfo}
              officialProductDetails={officialProductDetails}
              zwcpDetails={zwcpDetails}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              reasonOrDescription={reasonOrDescription}
              setReasonOrDescription={setReasonOrDescription}
              devDetails={devDetails}
              setDevDetails={setDevDetails}
              specChgReq={specChgReq}
              setSpecChgReq={setSpecChgReq}
              originals={originals}
              setOriginals={setOriginals}
              multipleReplacements={multipleReplacements}
              setMultipleReplacements={setMultipleReplacements}
              singleReplacementText={singleReplacementText}
              setSingleReplacementText={setSingleReplacementText}
              npdNumber={npdNumber}
              setNpdNumber={setNpdNumber}
              deviationType={deviationType}
              showAdditionalComponents={showAdditionalComponents}
              setShowAdditionalComponents={setShowAdditionalComponents}
              additionalComponents={additionalComponents}
              onGetAdditionalComponents={onGetAdditionalComponents}
              onSetAdditionalComponents={onSetAdditionalComponents}
              onSaveAndReview={onSaveAndReview}
              setLoading={setLoading}
              setIngredientStatement={setIngredientStatement}
            />
          </Col>
          <Col sm={12} md={6}>
            {onSaveAndReview ? <ContactInfoData
              individualEmailsList={individualEmailsList}
              ccIndividualEmailsList={ccIndividualEmailsList}
              setccIndividualEmailsList={setccIndividualEmailsList}
              setIndividualEmailsList={setIndividualEmailsList}
              distributionLists={distributionLists}
              userInfo={userInfo}
              setApproversSelected={setApproversSelected}
              approversSelected={approversSelected}
              setCCSelected={setCCSelected}
              ccSelected={ccSelected}
              setContactInfoVerified={setContactInfoVerified}
            /> : undefined}
          </Col>
        </Row>

        {/* Save will create the deviation on edit status without selecting emails, it should return to show main screen*/}
        {/* Save and review will create the deviation on edit status and prompt the user to choose distribution lists or emails to submit the deviation and change it to pending status*/}
        {canShowSaveButtons && !onSaveAndReview ? (
          <Row className="mt-5">
            <Col>
              <Button
                size="small"
                variant="outlined"
                disabled={!areVerified}
                className={areVerified ? 'darkGrayColor' : 'lightGrayColor'}
                onClick={() => saveHandler(false)}
              >
                Save
              </Button>
            </Col>

            <Col>
              <Button
                size="small"
                variant="outlined"
                disabled={!areVerified}
                className={areVerified ? 'darkGrayColor' : 'lightGrayColor'}
                onClick={saveAndReviewHandler}
              >
                Save and Review
              </Button>
            </Col>
          </Row>
        ) : !canShowSaveButtons && onSaveAndReview ? <Row className="mt-5">
          <Col>
            <Button
              size="small"
              variant="outlined"
              className={'lightGrayColor'}
              onClick={cancelReview}
            >
              Cancel
            </Button>
          </Col>

          <Col>
            <Button
              size="small"
              variant="outlined"
              disabled={!contactInfoVerified}
              className={contactInfoVerified ? 'darkGrayColor' : 'lightGrayColor'}
              onClick={submitHandler}
            >
              Submit it!
            </Button>
          </Col>
        </Row> : undefined}
      </Col>
    </Container>
  );
};

export default DeviationRequest;
